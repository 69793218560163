var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-1"
  }, _vm._l(_vm.seatMap, function (rows, index) {
    return _c('b-row', {
      key: "row-wrap-".concat(index),
      class: "\n    ".concat(index === 0 ? 'mb-3' : '', "\n    ")
    }, _vm._l(rows, function (colSeats, indexColSeat) {
      return _c('div', {
        key: "row-seat-".concat(indexColSeat),
        staticClass: "d-flex flex-nowrap px-2"
      }, _vm._l(colSeats, function (seat) {
        return _c('div', {
          key: seat
        }, [_c('TicketSeatButton', {
          attrs: {
            "seat": _vm.resolveDataSeat(_vm.availSeats, seat, _vm.itineraryIndex),
            "isRight": _vm.isRightSeat(seat)
          }
        })], 1);
      }), 0);
    }), 0);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }