<template>
  <div class="my-1">
    <b-row
      v-for="(rows, index) of seatMap"
      :key="`row-wrap-${index}`"
      :class="`
      ${index === 0 ? 'mb-3' :''}
      `"
    >
      <div
        v-for="(colSeats, indexColSeat) of rows"
        :key="`row-seat-${indexColSeat}`"
        class="d-flex flex-nowrap px-2"
      >
        <div
          v-for="seat of colSeats"
          :key="seat"
        >
          <TicketSeatButton
            :seat="resolveDataSeat(availSeats, seat, itineraryIndex)"
            :isRight="isRightSeat(seat)"
          />
        </div>
      </div>
    </b-row>
  </div>
</template>

<!-- SEAT -->
<script>
// import { ref } from '@vue/composition-api'
import { BRow } from 'bootstrap-vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BRow,
    TicketSeatButton: () => import('./TicketSeatButton.vue'),
  },
  props: {
    availSeats: {
      type: Array,
      default: () => [],
    },
    itineraryIndex: {
      type: Number,
      default: () => 0,
    },
  },
  setup() {
    const {
      resolveDataSeat,
    } = useTrainHandle()
    function isRightSeat(index) {
      return index % 2 !== 0
    }

    const seatMap = [
      [
        [1, 8, 9, 16, 17, 24, 25, 32, 33, 40, 41, 48, 49, 56, 57, 64, 65, 72, 73, 80],
        [2, 7, 10, 15, 18, 23, 26, 31, 34, 39, 42, 47, 50, 55, 58, 63, 66, 71, 74, 79],
      ],
      [
        [3, 6, 11, 14, 19, 22, 27, 30, 35, 38, 43, 46, 51, 54, 59, 62, 67, 70, 75, 78],
        [4, 5, 12, 13, 20, 21, 28, 29, 36, 37, 44, 45, 52, 53, 60, 61, 68, 69, 76, 77],
      ],
    ]

    return {
      seatMap,
      isRightSeat,
      resolveDataSeat,
    }
  },
}
</script>

<style lang="">

</style>
